<template>
  <v-container data-app class="app-container">
    <BrowserDetect />
    <v-card elevation="2" class="mx-auto my-12" max-width="480" color="#edf2f7">
      
      <!-- <v-img
      class="white--text align-end"
      src="../../public/background2.png"
    > -->
      
   
      <div class="d-flex justify-space-between align-center">
        <div>
          <v-card-title class="pt-2 pb-0 flex justify-between">
            <v-img
              class="white--text align-end"
              src="../assets/ggebi-sm.png"
              max-width="200"
            />                
          </v-card-title>
        </div>
        <v-btn
          color="blue-grey"
          @click="connectWallet"
          v-if="!connected"
          class="ma-2 white--text"
        >
          CONNECT
        </v-btn>
      </div>
      <v-card-subtitle class="pb-0"
        >My Earnings</v-card-subtitle
      >
      <v-card-title class="justify-center text-h4 font-weight-medium mb-4">
        <div v-if="loading_total != true">{{ myRewards }} GGB</div>
        <v-progress-circular
          v-else
          :width="3"
          :size="30"
          indeterminate
          color="primary"
        ></v-progress-circular>
        
      </v-card-title>
      <div class="claim-rewards-container">
        <v-btn class="claim-btn" color="green" outlined @click="claim" :disabled="myRewards == 0" >
          Claim Rewards
        </v-btn>
      </div>
      <v-simple-table class="table">
        <template v-slot:default>
          <tbody class="staking-table">
            <tr>
              <td>APY</td>
              <td>
                <div v-if="loading_apr != true">15%</div>
                <v-progress-circular
                  v-else
                  :width="3"
                  :size="20"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </td>
            </tr>
            <tr>
              <td>My wallet's GGB</td>
              <td>
                <div v-if="loading_wallet != true">{{ myDisplayGGB }} GGB</div>
                <v-progress-circular
                  v-else
                  :width="3"
                  :size="20"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </td>
            </tr>
            <tr>
              <td>My Staked GGB</td>
              <td>
                <div v-if="loading_staked != true">{{ myStaked }} GGB</div>
                <v-progress-circular
                  v-else
                  :width="3"
                  :size="20"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </td>
            </tr>
            <tr>
              <td>Total Locked GGB</td>
              <td>{{ totalStaked }} GGB</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div v-if="staked" class="withdraw-btn-container">
        <v-btn
          class="stake-btn"
          color="#999CFC"
          @click=";(dialog = true), (dialogHeader = 'Stake')"
          width="45%"
        >
          Add Stake
        </v-btn>
        <v-btn class="stake-btn" color="error" @click="unStake" width="45%">
          Unstake
        </v-btn>

      </div>
      <div v-else class="stake-btn-container">
        <v-btn
          v-if="approved"
          color="#999CFC"
          class="stake-btn"
          @click=";(dialog = true), (dialogHeader = 'Stake')"
          >Stake</v-btn
        >
        <!-- :color="disable_approve ? 'secondary' : 'primary'" -->
        <v-btn
          
          v-else
          @click="approve"
          class="stake-btn"
          dark
          color="black"
          :class="disable_approve && 'disabled'"
        >
          Approve
        </v-btn>
      </div>
      <div class="withdraw-btn-container">
        <div><a href="https://ggebitoken.com" target=_blank>Homepage</a></div>
        <div><a href="https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955&inputCurrency=0x3F29Cf53c02f4E1947557127295672F60f8e6898" target=_blank>Buy GGB from Pancakeswap</a></div>        
      </div>
       <!-- </v-img> -->
    </v-card>
    <v-dialog v-model="processingDialog" persistent width="500">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2"> 
          Loading... 
        </v-card-title>

        <v-card-text class="mt-2 mb-5">
          {{processingText}} your GGB. Please wait...
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogHeader }} GGB tokens</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="Amount"
                  v-model="amount"
                  v-if="loading"
                  loading
                  :rules="[
                    () => amount <= myGGB || 'You cannot stake more than your GGB balance',
                    () => amount % 1 == 0 || 'Please enter whole numbers only'
                  ]"
                ></v-text-field>
                <v-text-field
                  label="Amount"
                  v-model="amount"
                  type='number'
                  :rules="[
                    () => amount <= myGGB || 'You cannot stake more than your GGB balance',
                    () => amount % 1 == 0 || 'Please enter whole numbers only'
                  ]"
                  v-else
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="handleAgree" :disabled = "amount > myGGB || amount % 1 != 0"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const { address, abi, erc20_address, erc20_abi } = require('../../config')
import BrowserDetect from "./BrowserDetect.vue"
export default {
  name: 'StakingCard',
  components: { BrowserDetect },
  mounted() {
    this.init()
  },
  methods: {
    init: async function () {
      await this.getData()
    },
    approve: async function () {
      try {
        this.processingDialog = true
        this.processingText = 'Approving'
        const provider = new this.$ethers.providers.Web3Provider(
          window.web3.currentProvider
        )
        await provider.send('eth_requestAccounts', [])
        const signer = provider.getSigner()
        // console.log('Signer: ', signer)
        const erc20 = new this.$ethers.Contract(
          erc20_address,
          erc20_abi,
          signer
        )
        // console.log('ERC20 Address: ', erc20_address)
        const tx = await erc20.approve(
          address,
          this.$ethers.constants.MaxUint256
        )
        await tx.wait()
        await this.getData()
        this.processingDialog = false
      } catch (e) {
        console.log(e)
        this.processingDialog = false
      }
    },
    getData: async function () {
      try {
        const connected = await this.checkConnectedWalletExist()
        if (connected) {
          console.log('connected,', this.currentAccount)
        }
        let provider = new this.$ethers.providers.Web3Provider(
          window.web3.currentProvider
        )
        // const account = await provider.send('eth_requestAccounts', [])
        // console.log(account)
        const { chainId } = await provider.getNetwork()
        if (chainId !== 56) {
          this.disable_approve = true
          alert(
            'You are connected invalid network. \nPlease switch to Binance Smart Chain!!!'
          )
          return
        }
        // const RPC = 'https://data-seed-prebsc-1-s1.bnbchain.org:8545'
        const RPC = 'https://rpc.ankr.com/bsc'
        provider = new this.$ethers.providers.JsonRpcProvider(RPC)
        let stakingContract = new this.$ethers.Contract(
          address,
          abi,
          provider
        )
        let erc20 = new this.$ethers.Contract(
          erc20_address,
          erc20_abi,
          provider
        )
        this.loading_wallet = true
        this.loading_total = true
        this.loading_staked = true
        this.loading_reward = true
        this.loading_apr = true
        if (connected) {
          provider = new this.$ethers.providers.Web3Provider(
            window.web3.currentProvider
          )
          const signer = provider.getSigner()
          stakingContract = new this.$ethers.Contract(
            address,
            abi,
            signer
          )
          erc20 = new this.$ethers.Contract(
            erc20_address,
            erc20_abi,
            signer
          )
          const allowance = await erc20.allowance(this.currentAccount, address)
          if (Number(allowance)) this.approved = true
          else this.approved = false
          var tempBal = await erc20.balanceOf(this.currentAccount)
          const tRemainder = tempBal.mod(1e14)
          this.myDisplayGGB = this.$ethers.utils.commify(
            this.$ethers.utils.formatEther(tempBal.sub(tRemainder), {
              pad: true,
            })
          )
          this.myGGB = this.$ethers.utils.formatEther(tempBal)
          this.myGGB = parseInt(this.myGGB)
          this.loading_wallet = false
          const myStaked = await stakingContract.getBalance()
          this.myStaked = this.$ethers.utils.commify(
            Number(this.$ethers.utils.formatEther(myStaked))
          )
          this.loading_staked = false
          if (Number(myStaked)) this.staked = true
          else this.staked = false
          if (this.staked) {
            const myRewards = await stakingContract.earned(this.currentAccount)
            const remainder = myRewards.mod(1e14)
            this.myRewards = this.$ethers.utils.commify(
              this.$ethers.utils.formatEther(myRewards.sub(remainder), {
                pad: true,
              })
            )
            this.loading_reward = false
          }
          this.loading_reward = false
        } else {
          this.loading_wallet = false
          this.loading_staked = false
          this.loading_reward = false
        }
        const totalStaked = await stakingContract.totalStaked()
        this.totalStaked = this.$ethers.utils.commify(
          Number(this.$ethers.utils.formatEther(totalStaked))
        )
        // console.log(this.totalStaked)
        // this.totalStaked = this.totalStaked.slice(0,this.totalStaked.indexOf("."))
        // console.log(this.totalStaked.indexOf("."))
        this.loading_total = false
        const rewardRate = await stakingContract.rewardRate()
        if (Number(totalStaked)) {
          const apr = rewardRate.mul(3600 * 24 * 365 * 100).div(totalStaked)
          this.apr = this.$ethers.utils.commify(apr)
        } else this.apr = 0
        this.loading_apr = false
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    claim: async function () {
      try {
        this.processingDialog = true
        this.processingText = 'Claiming'
        const provider = new this.$ethers.providers.Web3Provider(
          window.web3.currentProvider
        )
        const signer = provider.getSigner()
        const stakingContract = new this.$ethers.Contract(address, abi, signer)
        // console.log(stakingContract)
        const tx = await stakingContract.getReward()
        // console.log(tx)
        await tx.wait()
        await this.getData()
        this.processingDialog = false
      } catch (e) {
        this.processingDialog = false
        console.log(e)
      }
    },
    stake: async function () {
      try {
        this.processingDialog = true
        this.processingText = 'Staking'
        const provider = new this.$ethers.providers.Web3Provider(
          window.web3.currentProvider
        )
        const signer = provider.getSigner()
        const stakingContract = new this.$ethers.Contract(address, abi, signer)
        console.log(stakingContract)
        const tx = await stakingContract.stake(
          this.$ethers.utils.parseEther(this.amount)
        )
        console.log(tx)
        await tx.wait()
        await this.getData()
        this.processingDialog = false
      } catch (e) {
        console.log(e)
        this.processingDialog = false
      }
    },
    unStake: async function () {
      try {
        this.processingDialog = true
        this.processingText = 'Unstaking'
        const provider = new this.$ethers.providers.Web3Provider(
          window.web3.currentProvider
        )
        const signer = provider.getSigner()
        const stakingContract = new this.$ethers.Contract(address, abi, signer)
        const tx = await stakingContract.withdraw()
        await tx.wait()
        await this.getData()
        this.processingDialog = false
      } catch (e) {
        this.processingDialog = false
        console.log(e)
      }
    },
    handleAgree: async function () {
      if (Number(this.amount) > 0) {
        this.loading = true
        if (this.dialogHeader === 'Stake') await this.stake()
        else await this.unStake()
        this.loading = false
        this.dialog = false
      } else return alert('invalid amount')
    },
    checkConnectedWalletExist: async function () {
      try {
        const { ethereum } = window
        if (!ethereum) {
          alert('Make sure you have metamask!')
          return false
        } else {
          // console.log('We have the ethereum object', ethereum)
        }
        const accounts = await ethereum.request({ method: 'eth_accounts' })
        if (accounts.length !== 0) {
          const account = accounts[0]
          // console.log('Found an authorized account:', account)
          this.currentAccount = account
          this.connected = true
          return true
        } else {
          console.log('No authorized account found')
          return false
        }
      } catch (error) {
        console.log(error)
        return false
      }
    },
    connectWallet: async function () {
      try {
        const { ethereum } = window
        if (!ethereum) {
          alert('Get MetaMask!')
          return
        }
        const accounts = await ethereum.request({
          method: 'eth_requestAccounts',
        })
        // console.log('Connected', accounts[0])
        this.currentAccount = accounts[0]
        this.connected = true
        await this.getData()
      } catch (error) {
        console.log(error)
      }
    },
    checkBalance() {
      console.log
    },
  },
  // computed: {
  //   rules() {
  //         value => (value || '').length <= 20 || 'Max 20 characters'
  //         return true
  //       }
  // },

  //Can be populated later
  data: () => ({
    currentAccount: '',
    connected: false,
    totalStaked: '0',
    myStaked: '0',
    myGGB: '0.0',
    myDisplayGGB: '0.0',
    myRewards: '0.0',
    approved: false,
    staked: false,
    dialog: false,
    dialogHeader: '',
    amount: 0,
    loading: false,
    apr: 0,
    loading_apr: false,
    loading_total: false,
    loading_wallet: false,
    loading_staked: false,
    loading_reward: false,
    disable_approve: false,
    processingDialog: false,
    processingText: 'Approving',
  }),
}
</script>
<style>
.rewards {
  color: red;
}
.stake-btn-container {
  text-align: center;
  padding: 24px;
}
.staking-table > tr > td {
  border: 0px solid !important;
}
.withdraw-btn-container {
  display: flex !important;
  justify-content: space-between;
  flex-direction: row;
  gap: 24px;
  padding: 24px;
  flex-wrap: wrap;
}
.claim-rewards-container {
  text-align: right;
  padding-right: 20px;
}
.claim-btn {
  border-radius: 10px;
}

.stake-btn {
  border-radius: 10px;
  width: 250px;
}

.add-btn {
  border-radius: 10px;
  width: 250px;
  color: white;
}

.min-w-52 {
  min-width: 52px !important;
  max-width: 52px !important;
}
.stretch {
  flex-grow: 1;
}
.app-container {
  max-width: 600px;
  margin: 150px auto;
}
.disabled {
    background-color: rgba(0, 0, 0, 0.35) !important;
    color: white;
    pointer-events: none;
}

.table {
  background-color: #edf2f7 !important;
}
/* .staking-table > tr > td:last-child { */
/*   text-align: right; */
/* } */
</style>
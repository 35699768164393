<template>
  <div class="browser-detect" v-if="isBrave">
    <v-alert color="#2A3B4D" border="left" dark dismissible>
      You are using a Brave browser. In order for it to detect Metamask, you need to set the browser's crypto wallet to none or you can follow the instructions <a target="_blank" href="https://help.matcha.xyz/en/articles/4217092-metamask-isn-t-working-with-the-brave-browser-how-do-i-fix-this">here.</a>
    </v-alert>
  </div>
</template>

<script>

export default {
  name: "BrowserDetect",
  data: () => ({
    isBrave: false
  }),
  async mounted () {
    this.$data.isBrave = navigator.brave && await navigator.brave.isBrave() || false
  }
}
</script>
<style scoped lang="scss">
.browser-detect {
  margin: auto;
  margin-top: 20px;
  padding: 20px;
  text-align: center;
  font-size: 16px;
  // background: #eee;
}
</style>
